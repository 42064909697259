<template>
  <span class="SearchTotal">
    访问人数:{{total}}
  </span>
  <div class="scrollBox bounce-top" @click="toaggPage()">
    <hr class="hr1"/>
    <span>SCROLL</span>
  </div>
  <div class="searchMainBox">
    <img class="searchLogo scale-up-center" src="@/assets/image/LOGO_big.png">
    <div class="searchBox scale-up-center">
      <!-- 搜索框 -->
      <span class="iconfont searchIcon">&#xe61f;</span>
      <span class="cline">|</span>
      <input ref="searchInput" class="searchInput" type="text" placeholder="搜索站内网站名称跳转……" v-model="seachVal" @keyup.enter="tosearch()"/>
    </div>

    <div class="searchFloatBox scale-up-center" v-if="searchList.length>0">
      <!-- 搜索提示框 -->
      <span class="searchFloatItem" v-for="(item,index) in searchList" :key="index" @click="toWeb(item[2])">
        {{item[1]}}
      </span>
    </div>

    <div class="hotListBox">
      <span class="hotListItemBox scale-up-center" v-for="(item,index) in hotList" :key="index">
        <span class="iconfont hotIcon">&#xe629;</span>
        <a :href="item.url">{{item.title}}</a>
      </span>
    </div>
  </div>
</template>

<script>
import router from "@/router/router";
import axios from "axios";

export default {
  name: "searchPage",
  data(){
    return{
      total:3000,
      seachVal:"",
      searchList:[],
      hotList:[
        {
          title:"超星课程平台",
          url:'http://i.chaoxing.com/'
        },{
          title:"MOOC",
          url:'https://www.icourse163.org/'
        },{
          title:"金融员工培训",
          url:'http://www.bettercredit.cn/'
        },{
          title:"金融教学资源库",
          url:'http://www.bettercredit.cn/'
        },
      ]
    }
  },
  watch:{
    seachVal(newVal){
      if (newVal != ''){
        this.tosearch()
      }else{
        this.searchList = []
      }
    }
  },
  methods:{
    toaggPage(){
      router.push('/aggregation')
    },
    getTotal(){
      axios.get('/server/api/getTotal.php').then((resp)=>{
        this.total = resp.data.total
      })
    },
    tosearch(){
      axios.get('/service/search.php?searchVal='+this.seachVal).then((resp)=>{
        if(resp.data == "0 结果"){
          this.searchList = [[404,"没有搜索到想要的结果/(ㄒoㄒ)/~~","#"]]
          console.log(this.searchList)
        }else{
          this.searchList = resp.data
        }

      })
    },
    toWeb(url){
      if (url == "#") return
      window.open(url)
    }
  },
  mounted() {
    this.getTotal()
  }
}



</script>

<style scoped>
.searchFloatBox{
  z-index: 99;
  display: flex;
  flex-direction: column;
  position: relative;
  top: -50px;
  padding: 20px;
  border-radius: 20px;
  background-color: #ffffff;
  max-width: 1000px;
  width: 90%;
  box-shadow: 0px 0px 10px #8f8f8f;
}
.searchFloatItem{
  padding: 20px;
  transition: all .3s ease-in-out;
}
.searchFloatItem:hover{
  background-color: #8f8f8f;
  border-radius: 10px;
}
.SearchTotal{
  position: absolute;
  font-size: 23px;
  top: 63px;
  right: 50px;
  color: #cbd3ea;
}
.searchLogo{
  width: 90%;
  max-width: 820px;
}
.searchMainBox{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.searchBox{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 1190px;
  width: 90%;
  height: 100px;
  background-color: #ffffff;
  border-radius: 20px;
  border: 2px solid #e7e7e7;
  margin-top: 80px;
  margin-bottom: 65px;
}
.searchIcon{
  font-size: 40px;
  color: #fce0a9;
  margin: 0px 55px;
}
.cline{
  font-size: 25px;
  color: #e2e2e2;
}
.searchInput{
  width: 100%;
  font-size: 30px;
  margin: 0px 35px;
  border: 0px;
  outline: none;
}
a{
  color: #ffffff;
  text-decoration: none;
  font-size: 20px;
}
.hotIcon{
  color: #fce0a9;
  margin: 0px 15px;
}
.hotListBox{
  display: flex;
  flex-direction: row;
}

.hotListItemBox{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #d2dbea99;
  border-radius: 10px;
  padding: 5px 15px 5px 0px;
  margin: 0px 10px;
}

@media (max-width: 1024px) {
  .hotListBox{
    flex-wrap: wrap;
  }
  .hotListItemBox{
    margin: 10px 10px;
  }
}

.scrollBox{
  position: absolute;
  width: 150px;
  height: 50px;
  z-index: 10;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: 0px auto;
  text-align: center;
  color: #91a3d3;
}
.hr1{
  border: none;
  border-bottom: 2px solid #91a3d3;
}

</style>
