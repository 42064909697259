<template>
  <div class="headerLogoBox">
    <img class="headerLogo" src="../../assets/image/LOGO_big.png" alt="LOGO"/>
  </div>
</template>

<script>
export default {
  name: "headerIndex"
}
</script>

<style scoped>
.headerLogoBox{
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.headerLogo{
  width: 80%;
  max-width: 1024px;
}
</style>
