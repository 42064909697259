<template>
  <div v-if="`${agg['state']}`==='1' && agg['list'].length>0" class="itemCardBox">
    <span class="itemCardTitle">{{agg['label']}}</span>
    <div class="cardBoxList">
      <div class="cardBox" v-for="item in agg['list']" :key="item['id']" :style="`background-color: #${item['color']}`" @click="toUrl(item['uri'])">
        <img class="cardLogo" :src="`http://zfc.peacedo.cn/upLoadImg/${item['logo_uri']}`">
        <span class="cardTitle">
          {{item['name']}}
        </span>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "itemCard",
  props:['agg'],//agg[0]是aggId,agg[1]是aggtitle,agg[2]是aggList
  data(){
    return{

    }
  },
  methods:{
    toUrl(url){
      if (url === '__file') {
          this.$emit('toFile')
        return
      }
      window.open(url)
      axios.get('/server/api/addTotal.php')
    }
  }
}
</script>

<style scoped lang="less">
.itemCardBox{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
  margin: 0 70px 5px 0; // 间隙为5px
  padding: 10px;
  width: calc((100% - 115px) / 2);  // 这里的10px = (分布个数3-1)*间隙5px, 可以根据实际的分布个数和间隙区调整
  min-width: calc((100% - 115px) / 2); // 加入这两个后每个item的宽度就生效了
  max-width: calc((100% - 115px) / 2); // 加入这两个后每个item的宽度就生效了
  &:nth-child(2n) { // 去除第3n个的margin-right
    margin-right: 0;
  }
}
.itemCardTitle{
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}
.cardBoxList{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.cardBox{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin: 0 70px 30px 0; // 间隙为5px
  padding: 10px;
  width: calc((100% - 110px) / 2);  // 这里的10px = (分布个数3-1)*间隙5px, 可以根据实际的分布个数和间隙区调整
  min-width: calc((100% - 110px) / 2); // 加入这两个后每个item的宽度就生效了
  max-width: calc((100% - 110px) / 2); // 加入这两个后每个item的宽度就生效了
  height: 250px;
  &:nth-child(2n) { // 去除第3n个的margin-right
    margin-right: 0;
  }
  border-radius: 10px;
  transition: all .3s ease-in-out;
}
.cardLogo{
  width: 50%;
  margin-bottom: 10px;
  transition:all .3s ease-in-out;
}
.cardTitle{
  font-size: 20px;
  transition:all .3s ease-in-out;
}
.cardBox:hover{
  border-radius: 20px;
  box-shadow: 0px 0px 20px #00000066;
  .cardLogo{
    width: 60%;
  }
  .cardTitle{
    font-weight: bold;
  }
}
@media (max-width: 800px) {
  .itemCardBox{
    width: 95%!important;
    min-width: 95%!important;
    max-width: 95%!important;
  }
  .cardBox{
    margin: 0 30px 15px 0; // 间隙为5px
    padding: 10px;
    height: 200px;
    width: calc((100% - 80px) / 2);  // 这里的10px = (分布个数3-1)*间隙5px, 可以根据实际的分布个数和间隙区调整
    min-width: calc((100% - 80px) / 2); // 加入这两个后每个item的宽度就生效了
    max-width: calc((100% - 80px) / 2); // 加入这两个后每个item的宽度就生效了
  }
}
</style>
