import searchPage from '@/components/searchPage/Search'
import addPageIndex from '@/components/aggPage/index'
import adminIndex from '@/components/admin/index'

export const routes = [
    {
        path:'/',
        component:addPageIndex
    },
    {
        path:'/aggregation',
        component:searchPage
    },
    {
        path:'/admin',
        component:adminIndex
    }
]
