<template>
  <!-- 悬浮添加窗口 -->
  <div class="floatWindow" v-if="showAddBox">
    <div class="addCard">
      <span>名称:</span>
      <input type="text" placeholder="请输入分类名称" v-model="addAgg[1]"/>
      <button @click="addAggfunc()">确定</button>
      <button @click="closeAddBox()">取消</button>
    </div>
  </div>
  <div class="mainBox">
    <!-- 左边 -->
    <div class="leftBox">
      <button @click="addAggFunction()">添加</button>
      <span class="operItemBox" v-for="item in operList" :key="item['id']" @click="checkAggFun(item)">
        {{item['label']}}
      </span>
    </div>
    <!-- 右边 -->
    <div class="rightBox">
      <div class="rightLogoBox">
        <img @click="toHome()" class="rightLogo" src="../../assets/image/LOGO_small.png">
      </div>
      <div class="adminRightShowBox" v-if="checkAgg[1]!= ''">
        <!-- 内容显示区域 -->
        <div class="rightHeadBox">
          {{checkAgg[1]}}
        </div>
        <div class="adminRightAddBox">
          <!-- 添加窗口 -->
          <div class="adminRightAddItem">
            <span class="adminRightAddItemSpan">名称</span>
            <input type="text" placeholder="请输入网站名称" v-model="addAggItem[2]"/>
          </div>
          <div class="adminRightAddItem">
            <span class="adminRightAddItemSpan">网站链接</span>
            <input type="text" placeholder="请输入网站链接" v-model="addAggItem[3]"/>
          </div>
          <div class="adminRightAddItem">
            <span class="adminRightAddItemSpan">网站图标</span>
            <input type="file" accept="image/jpeg" @change="getFile($event)"/>
          </div>
          <button class="adminRightBtn" @click="addAggItemfun()">添加</button>
          <button class="adminRightBtn" @click="toClear()">清空</button>
        </div>
        <div class="adminRightShowItemBox">
          <!-- 展示窗口 -->
          <div class="aggListItemBox">
            <span class="aggListItemId">ID</span>
            <span class="aggListItemName">名称</span>
            <span class="aggListItemUrl">网址</span>
            <span class="aggListItemColor">颜色</span>
            <span class="aggListItemImg">图像</span>
            <span class="aggListItemBtn">操作</span>
          </div>
          <div  class="aggListItemBox" v-for="(aggListItem,index) in aggItemList" :key="aggListItem['id']">
            <span class="aggListItemId">{{ aggItemList[index]['id'] }}</span>
            <input type="text" :readonly="aggItemListCheck[index]?false:true" class="aggListItemName" v-model="aggItemList[index]['name']"/>
            <input type="text" :readonly="aggItemListCheck[index]?false:true" class="aggListItemUrl" v-model="aggItemList[index]['uri']"/>
            <input type="text" :readonly="aggItemListCheck[index]?false:true" class="aggListItemColor" placeholder="请不要携带#" v-model="aggItemList[index]['color']"/>
            <img class="aggListItemImg aggListItemImgIamge" :src="`http://zfc.peacedo.cn/upLoadImg/${aggListItem['logo_uri']}`">
            <span class="aggListItemBtn">
              <button class="adminRightBtn" @click="toChangeItem(index)">{{aggItemListCheck[index]?'保存':'编辑'}}</button>
              <button class="adminRightBtn" @click="toDeleteItem(index)">删除</button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {nanoid} from 'nanoid'
import axios from 'axios'
import router from "@/router/router";
export default {
  name: "adminIndex",
  data(){
    return{
      showAddBox:false,
      addAgg:['',''],
      addAggItem:['','','','',''],
      operList:[],
      checkAgg:['',''],
      aggItemList:[],
      aggItemListCheck:[]
    }
  },
  methods:{
    getFile: function (event) {
      this.addAggItem[4] = event.target.files[0];
    },
    toHome(){
      router.push('/')
    },
    addAggFunction(){
      this.addAgg[1] = ''
      this.showAddBox = true
    },
    closeAddBox(){
      this.addAgg[1] = ''
      this.showAddBox = false
    },
    checkAggFun(item){
      this.checkAgg = item
      this.aggItemListCheck = []
      axios.get("/server/api/getPageCateItemList.php?aggid="+item['id']).then((resp)=>{
        this.aggItemList = resp.data.data
        for (let i = 0; i < this.aggItemList.length; i++) {
          this.aggItemListCheck.push(false)
        }
      })
    },
    addAggfunc(){
      // 添加一个分组名称
      if (this.addAgg[1] == ''){
        alert("请输入添加的分组名字")
        return
      }
      // 添加一个nanoid
      this.addAgg[0] = nanoid()
      console.log(this.addAgg)
      axios.post('/server/api/addPageCate.php', {
        addAgg:this.addAgg
      })
      .then(function () {
        alert("添加成功")
        location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
      this.closeAddBox()
    },
    getAggFunc(){
      axios.get("/server/api/getPageCate.php").then((resp)=>{
        this.operList = resp.data.data
      })
    },
    addAggItemfun(){
      if (this.addAggItem[2]=="" || this.addAggItem[3] == "" || this.addAggItem[4] == ""){
        alert("请输入正确的信息！")
        return;
      }
      this.addAggItem[0] = nanoid()
      this.addAggItem[1] = this.checkAgg['id']
      for (let i = 0; i < 5; i++) {
        if (this.addAggItem[i]=='') return
      }
      console.log(this.addAggItem)
      this.imgAdd(this.addAggItem[4])
    },
    imgAdd($file){
      // 第一步.将图片上传到服务器.
      var formdata = new FormData();
      formdata.append('logo', $file);
      formdata.append('id',this.addAggItem[0]);
      formdata.append('aggid',this.addAggItem[1]);
      formdata.append('title',this.addAggItem[2]);
      formdata.append('url',this.addAggItem[3]);
      axios({
        url: '/server/api/savePageConfig.php',
        method: 'post',
        data: formdata,
        headers: { 'Content-Type': 'multipart/form-data' },
      }).then((resp) => { //这里根据自己具体需求即可
        console.log(resp.data)
        alert("添加成功")
      })
    },
    toChangeItem(index){
      if (this.aggItemListCheck[index]){
        //保存
        this.aggItemListCheck[index] = false
        //递交数据到服务器
        axios.post('/server/api/updataPageItemById.php', {
          id: this.aggItemList[index]['id'],
          title:this.aggItemList[index]['name'],
          url:this.aggItemList[index]['uri'],
          color:this.aggItemList[index]['color']
        })
            .then(function (resp) {
              console.log(resp.data);
            })
            .catch(function (error) {
              console.log(error);
            });
      }else{
        //编辑
        this.aggItemListCheck[index] = true
      }
    },
    toDeleteItem(index){
      axios.post('/server/api/deletePageItemById.php', {
        id: this.aggItemList[index]['id'],
        aggid:this.checkAgg['id']
      })
          .then((resp)=> {
            console.log(resp.data);
            alert("删除成功");
            this.aggItemList.splice(index,1)
          })
          .catch(function (error) {
            console.log(error);
          });

    },
    toClear(){
      for (let i = 0; i < this.addAggItem.length; i++) {
        this.addAggItem[i] = ''
      }
    }
  },
  mounted() {
    this.getAggFunc()
  }
}
</script>

<style scoped>
.floatWindow{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #55555599;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.addCard{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  background-color: #ffffff;
  width: 400px;
  height: 100px;
  border-radius: 20px;
  box-shadow: 0px 0px 20px #8f8f8f;
}
.mainBox{
  display: flex;
  width: 100%;
  flex-direction: row;
}
.leftBox{
  display: flex;
  flex-direction: column;
  background-color: #d4a65f;
  width: 250px;
  padding-top: 100px;
}
.rightBox{
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  height: 100vh;
  flex: 1;
}
.rightLogoBox{
  display: flex;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 5px 2px 5px #eeeeee;
  justify-content: flex-end;
}
.rightLogo{
  width: 400px;
}
.operItemBox{
  font-weight: bold;
  color: #ffffff;
  padding: 10px 0px ;
  text-align: center;
  border-bottom: solid 1px #ffffff;
}
.rightHeadBox{
  margin: 20px;
  font-size: 30px;
  font-weight: bold;
}
.adminRightAddBox{
  background-color: #8f8f8f;
  border-radius: 20px;
  margin: 20px;
  padding: 20px;
}
.adminRightAddItem{
  padding: 10px 0px;
  display: flex;
  flex-direction: row;
}
.adminRightAddItemSpan{
  width: 80px;
  text-align-last: justify;
  margin-right: 10px;
}
.adminRightBtn{
  border-radius: 10px;
  padding: 5px 20px;
  margin: 5px;
  border: 0px;
}
.adminRightBtn:hover{
 background-color: #0086b3;
}
input{
  display: flex;
  flex: 1;
  max-width: 600px;
}
.aggListItemBox{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 20px 0px 20px;
}
.adminRightShowBox{
  display: flex;
  flex-direction: column;
  flex: 1;
}
.adminRightShowItemBox{
  flex: 1;
  background-color: #8f8f8f;
  overflow: auto;
}
.aggListItemId{
  overflow: auto;
  flex: 2;
}
.aggListItemName{
  flex: 3;
}
.aggListItemUrl{
  flex: 3;
}
.aggListItemColor{
  flex: 2;
}
.aggListItemImg{
  width: 50px;
}
.aggListItemImgIamge{
  height: 50px;
  border-radius: 10px;
}
.aggListItemBtn{
  text-align: center;
  width: 200px;
}
</style>
